<template>
    <div :class="['design-v2', isNissanUpgradeProgram ? 'font-nissan' : 'font-bmw']">
        <HeroV2 :is-error="isError" :is-nissan="isNissanUpgradeProgram" />
        <ViewAllInventory :is-error="isError" :is-nissan="isNissanUpgradeProgram" />
        <AllOffer />
        <FindMyPinModal />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import HeroV2 from "Modules/Home/components/Home/DesignV2/HeroV2.vue";
import ViewAllInventory from "Modules/Home/components/Home/DesignV2/ViewAllInventory.vue";
import AllOffer from "Modules/Home/components/Home/DesignV2/AllOffer.vue";
import FindMyPinModal from "Modules/Home/components/FindMyPinModal.vue";
import { get } from "vuex-pathify";
import { isNissanUpgradeCampaignProgram } from "@/libs/CampaignProgramEnum";

export default defineComponent({
    name: "DesignV2",
    components: { FindMyPinModal, AllOffer, ViewAllInventory, HeroV2 },
    data() {
        return {
            isError: false,
        };
    },
    computed: {
        programId: get("campaign/programId"),
        isNissanUpgradeProgram() {
            return isNissanUpgradeCampaignProgram(this.programId);
        },
    },
    created() {
        // Check for the query parameter in the URL
        const params = new URLSearchParams(window.location.search);
        if (params.has("error") && params.get("error") === "not-found") {
            this.isError = true;
        }
    },
});
</script>

<style lang="scss">
.design-v2.font-bmw {
    * {
        font-family: "BMWTypeNext Pro Regular", sans-serif;
    }
}
.design-v2.font-nissan {
    * {
        font-family: "Helvetica", sans-serif;
    }
}

.design-v2 {
    @include media-breakpoint-up("lg") {
        .slide-up-transition {
            $distance: 80px;
            .long {
                $distance: 80px;
            }
            .small {
                $distance: 20px;
            }

            transform: translateY($distance); /* Initial position */
            animation: fadeSlideUp 1.5s ease forwards; /* Animation properties */
            animation-delay: 2s; /* Delay before animation starts */
            opacity: 0;

            @keyframes fadeSlideUp {
                from {
                    opacity: 0;
                    transform: translateY($distance); /* Initial position */
                }
                to {
                    opacity: 1;
                    transform: translateY(0); /* Final position */
                }
            }
        }
    }
    @include media-breakpoint-down("sm") {
        video {
            object-fit: cover;
        }
    }
}
</style>
